import { LandingPage } from './section/landing';
import './App.css';
import { NavigationTabs } from './components/navigation';
import { TopBanner } from './components/top-banner';

import 'bootstrap/dist/css/bootstrap.min.css'
import Dialog from './section/dialog';
import Intro from './section/intro';
import Product from './section/product';
import Footer from './section/footer';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* <TopBanner />
        <NavigationTabs /> */}
      </header>
      <main>
        {/* <LandingPage />
        <Dialog />
        <Intro /> */}

        {/* <Product /> */}
      </main>
      {/* <Footer /> */}
    </div>
  );
}

export default App;
